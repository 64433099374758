import * as React from 'react';
import {Helmet} from 'react-helmet';
import {useMemo} from 'react';


export default function Seo({remoteMetaData/*, lang*/, meta, description, charSet, title}) {

	const usedMeta = useMemo(() => {
		const result = [
			{
				name: 'charSet',
				content: charSet,
			},
			...meta,
		];


		if (remoteMetaData.descriptionSEO ?? description) {
			result.push({
				name: `description`,
				content: remoteMetaData.descriptionSEO ?? description,
			});
		}
		if (remoteMetaData.descriptionSEO ?? description) {
			result.push({
				property: `og:description`,
				content: remoteMetaData.descriptionSEO ?? description,
			});
		}
		if (remoteMetaData.titleSEO ?? title) {
			result.push({
				property: `og:title`,
				content: remoteMetaData.titleSEO ?? title,
			});
		}

		if (remoteMetaData.ogImage?.localFile?.publicURL) {
			result.push({
				property: `og:image`,
				content: remoteMetaData.ogImage.localFile.publicURL,
			});
		}

		return result;
	}, [description, JSON.stringify(meta), remoteMetaData]);

	return (
		<Helmet
			meta={usedMeta}
			title={title}
		/>
	);
}

Seo.defaultProps = {
	// lang: `en`,
	meta: [],
	description: null,
	title: null,
	remoteMetaData: {},
	charSet: 'utf-8',
};
